export const membershipPackages = [
  {
    id: 'prod_IZaaDSPUYnktKT',
    object: 'product',
    active: true,
    attributes: [],
    created: 1611271473,
    default_price: 'price_1PRd8hDVqvipRL9o2p2TCtfg',
    description: null,
    images: [],
    livemode: false,
    metadata: {
      convert: 'Weekly: $1.92',
      initialPrice: '$99.99',
      offPercent: '80% OFF',
      perks: 'Unlimited Usage of all Furniture, Unlimited MyDecor Storage for Personal Items, Unlimited MyRoom, Storage for Personal Spaces, Access MyDecor Organizer, Access Item Search, Access Wishlist, Special Badge Shown on Profile',
      promote: 'true',
      promotitle: 'Best Value'
    },
    name: 'Yearly',
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: null,
    type: 'service',
    unit_label: null,
    updated: 1718383312,
    url: null,
    price: {
      id: 'price_1PRd8hDVqvipRL9o2p2TCtfg',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1611271473,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: 'membership',
      product: 'prod_IZaaDSPUYnktKT',
      recurring: {
        aggregate_usage: null,
        interval: 'year',
        interval_count: 1,
        trial_period_days: null,
        usage_type: 'licensed'
      },
      tax_behavior: 'unspecified',
      tiers_mode: null,
      transform_quantity: null,
      type: 'recurring',
      unit_amount: 9999,
      unit_amount_decimal: '9999'
    },
    nickname: 'membership',
    perks: [
      'Unlimited Usage of all Furniture',
      ' Unlimited MyDecor Storage for Personal Items',
      ' Unlimited MyRoom',
      ' Storage for Personal Spaces',
      ' Access MyDecor Organizer',
      ' Access Item Search',
      ' Access Wishlist',
      ' Special Badge Shown on Profile'
    ]
  },
  {
    id: 'prod_KCdmwVjG128rwl',
    object: 'product',
    active: true,
    attributes: [],
    created: 1631298654,
    default_price: 'price_1KLqyWDVqvipRL9oOWBP4Nh9',
    description: null,
    images: [],
    livemode: false,
    metadata: {
      convert: '$4.99/week',
      offPercent: '50% OFF',
      perks: 'Unlimited Usage of all Furniture, Unlimited MyDecor Storage for Personal Items, Unlimited MyRoom, Storage for Personal Spaces, Access MyDecor Organizer, Access Item Search, Access Wishlist, Special Badge Shown on Profile',
      promote: 'false'
    },
    name: 'Monthly',
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: null,
    type: 'service',
    unit_label: null,
    updated: 1662532844,
    url: null,
    price: {
      id: 'price_1KLqyWDVqvipRL9oOWBP4Nh9',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1643124628,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: 'membership',
      product: 'prod_KCdmwVjG128rwl',
      recurring: {
        aggregate_usage: null,
        interval: 'month',
        interval_count: 1,
        trial_period_days: null,
        usage_type: 'licensed'
      },
      tax_behavior: 'unspecified',
      tiers_mode: null,
      transform_quantity: null,
      type: 'recurring',
      unit_amount: 1999,
      unit_amount_decimal: '1999'
    },
    nickname: 'membership',
    perks: [
      'Unlimited Usage of all Furniture',
      ' Unlimited MyDecor Storage for Personal Items',
      ' Unlimited MyRoom',
      ' Storage for Personal Spaces',
      ' Access MyDecor Organizer',
      ' Access Item Search',
      ' Access Wishlist',
      ' Special Badge Shown on Profile'
    ]
  },
  {
    id: 'prod_KCbQgYyvHavMrp',
    object: 'product',
    active: true,
    attributes: [],
    created: 1631289877,
    default_price: 'price_1KLqzHDVqvipRL9owg5qWoDz',
    description: null,
    images: [],
    livemode: false,
    metadata: {
      perks: 'Unlimited Usage of all Furniture, Unlimited MyDecor Storage for Personal Items, Unlimited MyRoom, Storage for Personal Spaces, Access MyDecor Organizer, Access Item Search, Access Wishlist, Special Badge Shown on Profile',
      promote: 'false'
    },
    name: 'Weekly',
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: null,
    type: 'service',
    unit_label: null,
    updated: 1662532854,
    url: null,
    price: {
      id: 'price_1KLqzHDVqvipRL9owg5qWoDz',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1643124675,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: 'membership',
      product: 'prod_KCbQgYyvHavMrp',
      recurring: {
        aggregate_usage: null,
        interval: 'week',
        interval_count: 1,
        trial_period_days: null,
        usage_type: 'licensed'
      },
      tax_behavior: 'unspecified',
      tiers_mode: null,
      transform_quantity: null,
      type: 'recurring',
      unit_amount: 999,
      unit_amount_decimal: '999'
    },
    nickname: 'membership',
    perks: [
      'Unlimited Usage of all Furniture',
      ' Unlimited MyDecor Storage for Personal Items',
      ' Unlimited MyRoom',
      ' Storage for Personal Spaces',
      ' Access MyDecor Organizer',
      ' Access Item Search',
      ' Access Wishlist',
      ' Special Badge Shown on Profile'
    ]
  }
]
