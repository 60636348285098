import Link from 'next/link'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gotoLogIn } from '../../../../redux/actions/AuthActions'
import { setFilterInfoModalVisible } from '../../../../redux/communityReducers/PostActionsReducer'
import { getUsername } from '../../../../utils/getUsername'
import { noImageFeed } from '../../../../utils/normalizedata'
import DesignCanvas from '../../../../components/DesignCanvas'
import DesignHeader from '../../../../components/DesignDetail/DesignHeader'
import InspirationActionsMenu from '../../../../components/InspirationActionsMenu'
import CommentBtn from '../../../../components/social/CommentBtn'
import GiftBtn from '../../../../components/social/GiftBtn'
import LikeBtn from '../../../../components/social/LikeBtn'
import SocialContainer from '../../../../components/social/SocialContainer'
import { AnimatedFilterTag } from '../../../../components/Feed/AnimatedFilterTag'
import { AvatarAccessory, InfoHeader, InspirationFeedTile, InspirationItemButtons, InspirationItemContainer, MobileBlock, UserAvatar, UserName, UserProfile } from './Feed.styles'

/* Individual Feed Item */
export const InspirationItem = ({ id, url, refFn, type, user, savedList, sourcePost, likes, gifts, style, hideActionButton }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const dispatch = useDispatch()

  const logInCheck = e => {
    if (!isAuthenticated) {
      e.preventDefault()
      dispatch(gotoLogIn())

      return false
    }
  }

  return (
    <InspirationFeedTile style={style}>
      <MobileBlock>
        <DesignHeader data={sourcePost} id={sourcePost.objectId} savedList={savedList} inspirationType={type} hideActionButton={hideActionButton} />
      </MobileBlock>
      <InspirationItemContainer ref={refFn} id={id}>
        <Link href={isAuthenticated ? `/design/${id}` : '#'} passHref>
          <a onClick={logInCheck}>
            <DesignCanvas data={sourcePost} />
          </a>
        </Link>
        <InfoHeader>
          <Link href={isAuthenticated ? `/dm/${user.objectId}` : '#'}>
            <UserProfile onClick={logInCheck}>
              <UserAvatar loading="lazy" src={user?.funAvatarImageUrl || user?.thumbProfileImageFile?.url || ''} alt={user.uniqueDisplayName} onError={noImageFeed} />
              {user?.funAccessoriesImageUrl && <AvatarAccessory alt="" src={user.funAccessoriesImageUrl} loading="lazy" />}
              <UserName>{getUsername(user)}</UserName>
            </UserProfile>
          </Link>
          <InspirationActionsMenu type={type} location="home" inspirationId={id} savedList={savedList} sourcePost={sourcePost} />
        </InfoHeader>
        {sourcePost.animationInfo && (
          <AnimatedFilterTag
            onClick={() => {
              dispatch(
                setFilterInfoModalVisible({
                  visible: true,
                  post: sourcePost
                })
              )
            }}
            post={sourcePost}
          />
        )}
        <InspirationItemButtons>
          <LikeBtn postId={id} loc="home" numLikes={likes} />
          <CommentBtn data={sourcePost} postId={id} loc="home" commentsCount={sourcePost.numComments || '0'} noButton="nobutton" />
          <GiftBtn postId={id} loc="home" numGifts={gifts} />
        </InspirationItemButtons>
      </InspirationItemContainer>
      <MobileBlock>
        <SocialContainer loc="home" id={sourcePost.objectId} data={sourcePost} numLikes={likes} />
      </MobileBlock>
    </InspirationFeedTile>
  )
}
