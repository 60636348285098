import React from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import Image from 'next/image'

const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-left: 55px;
`

const Dots = styled(DotGroup)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 11px;
  gap: 6px;

  & button {
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: none;
    flex-shrink: 0;
    flex-grow: 0;
    background: #cecece;
    padding: 0;
    margin: 0;

    &:disabled {
      background: #ff5e6d;
    }
  }
`

const perks = [
  {
    src: 'https://cdn.decormatters.com/perks/all_furniture.png',
    type: 'furniture'
  },
  {
    src: 'https://cdn.decormatters.com/perks/myroom.png',
    type: 'room'
  },
  {
    src: 'https://cdn.decormatters.com/perks/mydecor_items.png',
    type: 'decor'
  },
  {
    src: 'https://cdn.decormatters.com/perks/design_drafts.png',
    type: 'templates'
  },
  {
    src: 'https://cdn.decormatters.com/perks/animated_filters.png',
    type: 'filter'
  },
  {
    src: 'https://cdn.decormatters.com/perks/more.png',
    type: 'featureList'
  }
]
//style={{ padding: '0 20px' }}
const MobileMembershipPerks = props => {
  return (
    <div style={{ marginBottom: '36px' }}>
      <CarouselProvider naturalSlideWidth={303} naturalSlideHeight={136} totalSlides={perks.length} visibleSlides={1.0} infinite={true} isIntrinsicHeight={true} interval={3000} isPlaying={false}>
        <Slider>
          {perks.map((perk, index) => (
            <Slide key={`perkas=${index}`} index={index}>
              <SlideContainer>
                <Image src={perk.src} width={303} height={136} loading="lazy" onClick={() => props.setFeatureModalData(perk.type)} />
              </SlideContainer>
            </Slide>
          ))}
        </Slider>
        <Dots />
      </CarouselProvider>
    </div>
  )
}

export default MobileMembershipPerks
