import React, { useState } from 'react'
import styled from 'styled-components'

import beforeImg from '../../assets/banner-before.avif'
import afterImg from '../../assets/banner-after.avif'
import moveIcon from '../../assets/beforeafter-icon.svg'
import Image from 'next/image'

const Root = styled.div`
  position: relative;
  width: 50vw;
  aspect-ratio: 790 / 570;
  border-radius: 20px 100px 20px 20px;
  overflow: hidden;

  @media (max-width: 1000px) {
    width: 45vw;
    border-radius: 10px 100px 10px 10px;
  }
  @media (max-width: 743px) {
    border-radius: 10px 60px 10px 10px;
    width: 95vw;
  }
`

const Img = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
`

const Slider = styled.input`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  margin: 0;
  transition: all 0.2s;

  &::-webkit-slider-thumb {
    // width: 9px;
    -webkit-appearance: none;
    appearance: none;
    width: 0.5vw;
    height: 100vh;
    background: white;
    cursor: pointer;
    @media (max-width: 800px) {
      width: 4px;
    }
  }
  &::-moz-range-thumb {
    width: 0.5vw;
    height: 100vh;
    background: white;
    cursor: pointer;

    @media (max-width: 800px) {
      width: 4px;
    }
  }
`

const SliderButton = styled.div`
  // width: 35px;
  // height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.85vw;
  width: 1.85vw;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  background-color: white;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1350px) {
    & img {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    width: 15px;
    height: 15px;
  }
`

const maxValue = 200
const BeforeAfter = () => {
  const [progress, setProgress] = useState(maxValue / 4.5)
  const position = (progress / maxValue) * 100

  const handleSlide = e => {
    setProgress(e.target.value)
  }

  return (
    <Root>
      <Img>
        <Image src={afterImg} layout="fill" priority />
      </Img>
      <Img
        style={{
          width: `${position}%`,
          backgroundImage: `url(${beforeImg})`
        }}
      />
      <Slider
        type="range"
        min="1"
        max={maxValue}
        value={progress}
        onChange={handleSlide}
      />
      <SliderButton style={{ left: `calc(${position}% - 0.07vw)` }}>
        <Image width={38} height={21} src={moveIcon} />
      </SliderButton>
    </Root>
  )
}

export default BeforeAfter
