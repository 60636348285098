import Image from 'next/image'
import React from 'react'
import {
  ButtonsOverlay,
  TemplateCardWrapper,
  CreateDesignButton
} from './TemplateCard.styles'

export const TemplateCard = ({ data, onClick }) => {
  return (
    <TemplateCardWrapper onClick={onClick}>
      <Image
        src={data.cfImageUrl}
        alt={data.title}
        width={300}
        height={300}
        sizes="(max-width: 800px) 140px, (max-width: 1024px) 240px, (max-width: 1200px) 240px, 300px"
      />
      <ButtonsOverlay>
        <CreateDesignButton onClick={onClick}>Create Design</CreateDesignButton>
      </ButtonsOverlay>
    </TemplateCardWrapper>
  )
}
