import styled from 'styled-components'

export const MyEventBannerSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  padding: 80px 62px 60px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 33px 9px 60px 30px;
    gap: 21px;
  }

  @media (max-width: 768px) {
    padding: 33px 34px 60px 34px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
    padding: 24px 16px;
  }

  @media (min-width: 1800px) {
    justify-content: center;
  }

  @media (min-width: 1728px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (min-width: 2560px) {
    padding-left: 180px;
    padding-right: 180px;
  }
`

export const MyEventBannerSectionImage = styled.div`
  width: 784px;
  height: 565px;
  position: relative;

  @media (max-width: 1024px) {
    width: 526px;
    height: 377px;
  }

  @media (max-width: 1000px) {
    width: 339px;
    height: 244px;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }

  @media (min-width: 1800px) {
    width: 47vw;
    height: auto;
  }
`

export const MyEventBannerSectionTextContent = styled.div`
  width: 650px;

  @media (max-width: 1024px) {
    & img {
      width: 66.5px;
      height: 35px;
    }
  }

  @media (max-width: 1020px) {
    width: 100%;

    & img {
      height: 32px;
      width: 66.5px;
    }

    & button {
      margin: 0 auto;
      font-size: 14px;
      padding: 12px 50px;
      width: auto;
      min-width: auto;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    & img {
      height: 35px;
      width: 66.5px;
    }

    & button {
      width: 200px;
      height: 48px;
      font-size: 18px;
      padding: 0;
    }
  }

  @media (max-width: 600px) {
    & button {
      min-width: 180px;
      height: 42px;
      border-radius: 37px;
      font-size: 14px;
      padding: 12px 40px;
    }
  }

  @media (min-width: 1800px) {
    width: 40vw;

    & button {
      font-size: 1.1vmax;
      width: 13vw;
      height: 3vw;
    }
  }
`

export const MyEventBannerButtonAligner = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`

export const MyEventBannerSectionTitle = styled.h2`
  font-size: 40px;
  line-height: 50px;
  margin-top: 30px;
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media (max-width: 1020px) {
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 18px;
    margin-top: 18px;
  }

  @media (min-width: 1800px) {
    font-size: 1.8vmax;
    line-height: 2.3vmax;
  }
`

export const MyEventBannerSectionSubtitle = styled.p`
  font-size: 24px;
  line-height: 38px;
  margin-top: 0;
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 1020px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 18px;
  }

  @media (min-width: 1800px) {
    font-size: 1.1vmax;
    line-height: 1.8vmax;
  }
`
