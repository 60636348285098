import styled from 'styled-components'

export const GradientButtonWrapper = styled.button`
  font-family: Helvetica Neue;
  min-width: 224px;
  height: 50px;
  background: linear-gradient(
    270deg,
    #ff9d74 0%,
    #ff5e6d 37.4%,
    #ff4f7e 74.69%,
    #ff6d90 100%
  );
  border-radius: 70px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  border: none;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      linear-gradient(
        270deg,
        #ff9d74 0%,
        #ff5e6d 37.4%,
        #ff4f7e 74.69%,
        #ff6d90 100%
      );
  }

  @media (max-width: 600px) {
    min-width: 180px;
    height: 42px;
    border-radius: 37px;
    font-size: 14px;
  }
`
