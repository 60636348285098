import styled, { keyframes } from 'styled-components'

export const FeedContainer = styled.div`
  width: 100vw;
  box-sizing: border-box;
`

export const InspirationFilterTag = styled.button`
  position: absolute;
  bottom: ${props => (props.mode === 'right' ? '14px' : '8px')};
  ${props => (props.mode === 'right' ? `right: 14px;` : `left: 14px;`)}
  background: rgba(0, 0, 0, 0.4);
  border-radius: 61px;
  padding: 7px 10px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
`

export const InspirationFilterIcon = styled.img`
  margin-right: 8px;
  filter: invert(100%);
  width: 17px !important;
  height: 16px !important;
`

export const InspirationFilterArrow = styled.img`
  margin-left: 8px;
  width: 8px !important;
  height: 8px !important;
  filter: brightness(2);
`

export const MobileBlock = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;

    & > div {
      margin: 0;
    }
  }
`

export const InfoHeader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: none;
  z-index: 1;
  padding: 10px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: none !important;
  }
`

const appearAnimation = keyframes`
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`

export const InspirationImage = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #979797;
  transition: all 0.5s ease;
  animation: ${appearAnimation} 1s;
  animation-fill-mode: forwards;
  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 10px;
    min-height: 350px;
  }
`

export const InspirationItemButtons = styled.div`
  display: none;
  position: absolute;
  bottom: 10px;
  right: 10px;

  @media (max-width: 768px) {
    display: none !important;
  }
`

export const InspirationItemContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:hover ${InfoHeader} {
    display: flex;
  }

  &:hover ${InspirationItemButtons} {
    display: flex;
  }

  &:hover a {
    filter: brightness(80%);
  }

  &:hover ${InspirationFilterTag} {
    background: rgba(255, 255, 255, 0.8);

    & > span {
      display: none;
    }

    & > img:first-child {
      filter: invert(20%) sepia(8%) saturate(1462%) hue-rotate(207deg) brightness(98%) contrast(87%);
    }

    & > img:last-child {
      filter: invert(100%);
    }
  }

  @media (max-width: 1100px) {
    min-height: auto;
    width: 100%;
    height: auto;
  }
`

export const InspirationFeedTile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;

  height: auto;
  width: auto;

  @media (min-width: 1069px) {
    &:nth-child(3) {
      grid-column-start: -2;
      grid-column-end: 5;
      grid-row-start: -2;
      grid-row-end: 2;
    }
  }

  /*
  &:nth-child(2) {
    grid-column-start: -2;
    grid-column-end: 5;
    grid-row-start: -2;
    grid-row-end: 2;
  }
  */
  /*
  @media (min-width: 1400px) {
    &:nth-child(5) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 4;
    }
  }
 
  @media (max-width: 1020px) {
    height: auto;
    width: auto;
  }
 */
`

export const UserProfile = styled.div`
  width: 48px;
  height: 48px;
  align-items: center;
  display: flex;
  align-items: center;
`

export const UserAvatar = styled.img`
  border-radius: 40px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #979797;
  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 50%;
  }
`

export const AvatarAccessory = styled.img.attrs(props => ({
  alt: props.alt || ''
}))`
  width: 52px;
  height: 52px;
  position: absolute;
  left: 8px;
  top: 8px;
`

export const UserName = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
`

export const InspirationsFeedTitle = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-overflow: ellipsis;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
`

export const InspirationsFeedContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

export const InspirationsWrapper = styled.div`
  margin: 20px 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 320px);
  grid-auto-rows: minmax(250px, auto);
  grid-auto-flow: dense;

  @media (max-width: 1010px) {
    grid-template-columns: repeat(3, minmax(auto, 315px));
    grid-auto-rows: auto;
  }

  @media only screen and (max-width: 700px) {
    display: block;
    margin: 0;
  }
`

export const PostsGridParent = styled.section`
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  height: ${props => (props.isAuthenticated ? '90vh' : '94vh')};

  @media (max-width: 800px) {
    height: 93vh;
  }
`

export const PostsGridInnerBlock = styled.div`
  height: ${props => props.height}px;
  width: 100vw;
  position: relative;
`

export const MasonryGrid = styled.div`
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(5, 18vw);
  grid-auto-rows: 18vw;
  grid-auto-flow: dense;
  padding: 10px 27px;
  justify-content: center;
  direction: ${props => (props.reversed ? 'rtl' : 'ltr')};

  & > * {
    direction: ltr;
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, 23vw);
    grid-auto-rows: 23vw;
  }

  @media (min-width: 800px) and (max-width: 1300px) {
    grid-template-columns: repeat(3, 30vw);
    grid-auto-rows: 30vw;
  }

  @media (min-width: 800px) {
    ${props => (props.static ? '' : 'position: absolute;')}
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(${props => (props.translateY ? `${props.translateY}px` : 0)});
  }

  @media (max-width: 800px) {
    display: block;
    padding-bottom: 0;
    padding-top: 0;
    padding: 0 0;
  }

  @media (min-width: 1700px) {
    padding-left: 100px;
    padding-right: 100px;

    grid-template-columns: repeat(5, 16.7vw);
    grid-auto-rows: 16.7vw;
  }

  @media (min-width: 2560px) {
    padding-left: 180px;
    padding-right: 180px;
  }
`

export const AdContainer = styled.div`
  --ad-padding: calc((100vw - (18vw * 5) - (22px * 4)) / 2);

  margin-left: var(--ad-padding);
  margin-right: var(--ad-padding);
  border-radius: 10px;
  overflow: hidden;

  transform: translateY(${props => (props.translateY ? `${props.translateY}px` : 0)});

  display: ${props => (!!props.height ? 'block' : 'none')};
  height: ${props => props.height}px;

  @media (min-width: 800px) and (max-width: 1300px) {
    --ad-padding: calc((100vw - (30vw * 3) - (21px * 2)) / 2);
  }

  @media (min-width: 1700px) {
    --ad-padding: calc((100vw - (16.7vw * 5) - (22px * 4)) / 2);
  }

  @media (max-width: 800px) {
    display: none;
  }
`

export const LoadingIndicatorContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100px;
`
