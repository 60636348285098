import React from 'react'
import styled from 'styled-components'

const ActionContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 20px;

  background-color: #fff;
  @media (min-width: 769px) {
    position: relative;
    background-color: transparent;
  }
`

const ModalActionContainer = ({ children }) => {
  return <ActionContainer>{children}</ActionContainer>
}

export default ModalActionContainer
