import React, { useRef } from 'react'
import {
  BackgroundImage,
  BackgroundImageDesktop,
  BackgroundImageMobile,
  CancelText,
  Grid,
  GridSection,
  GridSectionTitle,
  GridSectionTitleText,
  MembershipBlockWrapper,
  MobileMembershipBlockWrapper,
  MobileTitle,
  PackagesContainer,
  Privilege,
  PrivilegeList,
  Subtitle,
  Title,
  VideoContainer
} from './MembershipLandingAdvert.styles'
import membershipBgImage from '../../assets/membership/membershipBackground.png'
import membershipMobileBgImage from '../../assets/seo/MembershipBGMobile.png'
import membershipCrownIcon from '../../assets/membership/membershipCrown.svg'
import Image from 'next/image'
import Packages from '../MembershipModal/components/Packages'
import { membershipPackages } from './packages'
import { privileges } from '../MembershipModal/components/privileges'
import { useDispatch } from 'react-redux'
import { gotoLogIn } from '../../redux/actions/AuthActions'
import iphoneScreenImage from '../../assets/membership/iphone-screen.png'
import { useInViewEffect } from 'react-hook-inview'
import { logEvent } from '../../utils/firebase'
import { useIsMobile } from '../../hooks/useIsMobile'
import { MobileMembershipSection } from './MobileMembershipSection'

export const MembershipLandingAdvert = props => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const videoRef = useRef()

  const ref = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)

        videoRef?.current?.play()
      }
    },
    { threshold: 1 }
  )

  if (isMobile)
    return (
      <MobileMembershipSection
        packages={membershipPackages}
        onSelect={() => {
          logEvent('join_now_click_landing', {})
          dispatch(gotoLogIn())
        }}
        {...props}
      />
    )
  return (
    <MembershipBlockWrapper>
      <BackgroundImageDesktop src={membershipBgImage} layout="fill" />
      <BackgroundImageMobile src={membershipMobileBgImage} layout="fill" />
      <Title>Join Our Membership</Title>
      <Subtitle>Unlock all premium design tools and features. Upgrade your house design app experience. Upload personal rooms and items to design your dream home.</Subtitle>
      <Grid>
        <GridSection mobileHide>
          <VideoContainer ref={ref}>
            <video ref={videoRef} muted loop controls={false} src="/assets/video_membership.mp4" />
            <img src={iphoneScreenImage} alt="" />
          </VideoContainer>
        </GridSection>
        <GridSection>
          <GridSectionTitle>
            <Image src={membershipCrownIcon} width={42} height={39} alt="" />
            <GridSectionTitleText>15+ Privileges</GridSectionTitleText>
          </GridSectionTitle>
          <PrivilegeList>
            {privileges.slice(0, 9).map(({ label, background, type }) => (
              <Privilege key={type} onClick={() => props.setFeatureModalData(type)}>
                <Image src={background} loading="lazy" layout="fill" />
                {label}
              </Privilege>
            ))}
          </PrivilegeList>

          <Packages
            packages={membershipPackages}
            onSelect={() => {
              logEvent('join_now_click_landing', {})
              dispatch(gotoLogIn())
            }}
          />

          <CancelText>Buy with Confidence.</CancelText>
        </GridSection>
      </Grid>
    </MembershipBlockWrapper>
  )
}
