import Image from 'next/image'
import { AsSeenOnBlock } from '../Create.styles'

import adLogoImg from '../../../assets/seen-on-logos/ad-logo.png'
import atLogoImg from '../../../assets/seen-on-logos/at-logo.png'
import biLogoImg from '../../../assets/seen-on-logos/bi-logo.png'
import cbLogoImg from '../../../assets/seen-on-logos/cb-logo.png'
import ftLogoImg from '../../../assets/seen-on-logos/ft-logo.png'
import hgLogoImg from '../../../assets/seen-on-logos/hg-logo.png'
import mittrLogoImg from '../../../assets/seen-on-logos/mittr-logo.png'
import rcLogoImg from '../../../assets/seen-on-logos/rc-logo.png'
import yLogoImg from '../../../assets/seen-on-logos/y-logo.png'

const HomeAsSeenOn = props => {
  return (
    <AsSeenOnBlock>
      <span>As Seen On...</span>
      <Image src={biLogoImg} width={79} height={32} alt="Business Insider" />
      <Image src={mittrLogoImg} width={76} height={38} alt="MIT Technology Review" />
      <Image src={atLogoImg} width={114} height={41} alt="Apartment Therapy" />
      <Image src={adLogoImg} width={110} height={40} alt="Architectural Digest" />
      <Image src={hgLogoImg} width={96} height={37} alt="House & Garden" />
      <Image src={ftLogoImg} width={132} height={31} alt="Furniture Today" />
      <Image src={rcLogoImg} width={104} height={19} alt="realtor.com" />
      <Image src={cbLogoImg} width={84} height={25} alt="Coldwell Banker" />
      <Image src={yLogoImg} width={97} height={26} alt="Yahoo" />
    </AsSeenOnBlock>
  )
}

export default HomeAsSeenOn
