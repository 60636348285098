import Image from 'next/image'
import styled from 'styled-components'

export const TemplatesCategoryWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 100px;
  }

  @media (max-width: 1200px) {
    &:not(:first-child) {
      margin-top: 36px;
    }

    &:first-child {
      margin-top: 4px;
    }
  }
`

export const TemplatesTitleSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 44px 20px 62px;

  @media (max-width: 1200px) {
    padding: 0 19px 14px 30px;
  }

  @media (max-width: 800px) {
    padding: 0 11px 14px 12px;
  }

  @media (min-width: 1728px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (min-width: 2560px) {
    padding-left: 180px;
    padding-right: 180px;
  }
`

export const TemplatesCategoryTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
  width: auto;
  flex-shrink: 0;

  @media (max-width: 1400px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 20px;
  }

  @media (max-width: 744px) {
    font-size: 14px;
    font-style: normal;
  }
`

export const TemplatesCategoryTextsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 100px;

  @media (max-width: 1024px) {
    gap: 24px;
  }

  @media (max-width: 744px) {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
`

export const TemplatesCategorySubtitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #4c4c4c;
  margin: 0;
  display: ${props => (props.mobile ? 'none' : 'block')};
  max-width: 624px;

  @media (max-width: 1400px) {
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: normal;
  }

  @media (max-width: 744px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    width: auto;
    display: ${props => (props.desktop ? 'none' : 'block')};
  }
`

export const ActionButtonArrow = styled(Image)`
  @media (max-width: 800px) {
    width: 10px;
    height: 5.45px;
  }
`

export const ActionButton = styled.button`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ff5e6d;
  padding: 14px 20px;
  border: 2px solid #ff5e6d;
  border-radius: 33px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;

  & span {
    margin-right: 25px;
  }

  &:hover {
    background: #ff5e6d;
    color: white;

    ${ActionButtonArrow} {
      filter: invert(100%) sepia(0%) saturate(300%) hue-rotate(100deg)
        brightness(200%) contrast(100%);
    }
  }

  @media (max-width: 1200px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    padding: 7px 12px;
    width: auto;
    min-width: 80px;
    width: auto;
    height: 40px;
    flex-shrink: 0;
    margin-left: 10px;
    font-weight: bold;

    & span {
      margin-right: 8px;
    }
  }

  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 7px 12px;
    width: auto;
    min-width: 80px;
    width: auto;
    height: 30px;
    flex-shrink: 0;
    margin-left: 10px;

    & span {
      margin-right: 8px;
    }
  }
`

export const ScrollableZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: 0;
  padding-left: 60px;
  overflow: auto;
  gap: 30px;
  padding-bottom: 10px;
  padding-top: 10px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: 1200px) {
    padding-left: 30px;
    gap: 19px;
  }

  @media (max-width: 800px) {
    gap: 10px;
    padding-left: 14px;
  }

  @media (min-width: 1728px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (min-width: 2560px) {
    padding-left: 180px;
    padding-right: 180px;
  }
`
