import { useState } from 'react'
import MembershipLandingAdvert from '../../../components/MembershipLandingAdvert'
import BlogFooter from '../../../components/blog/Footer'
import HomeReview from './HomeReview'
import HomeShopping from './HomeShopping'
import HomeTitle from './HomeTitle'
import FeatureModal from '../../../components/DesignerModals/FeatureModal'
import HomeAsSeenOn from './HomeAsSeenOn'
import { HomeMyEvent } from './HomeMyEvent'
import HomeDecorate from './HomeDecorate'
import HomeGames from './HomeGames'
import HomeTemplates from './HomeTemplates'
import HomeExplore from './HomeExplore'

const Home = props => {
  const [featureModalData, setFeatureModalData] = useState(null)

  return (
    <>
      {featureModalData && <FeatureModal data={featureModalData} setData={setFeatureModalData} />}
      <HomeTitle {...props} />
      <HomeGames {...props} />
      <HomeTemplates {...props} />
      <HomeDecorate setModalData={setFeatureModalData} {...props} />
      <HomeExplore {...props} />
      <HomeMyEvent {...props} />
      <HomeAsSeenOn />
      <MembershipLandingAdvert setFeatureModalData={setFeatureModalData} />
      <HomeShopping />
      <HomeReview />
      <BlogFooter />
    </>
  )
}

export default Home
