import style from './designsection.module.css'
import { OutlineButton } from '../../../components/common/Buttons2024'

const HomeDesignSection = ({ title, description, short, children, onExploreClick, bodyClassname = {}, ...props }) => {
  return (
    <section className={`${style.designSection}`}>
      <div className={`${style.designSectionContainer}`}>
        <div className={`${style.designSectionHeader}`}>
          <div className={`${style.designSectionHeaderTileContainer}`}>
            <h2>{title}</h2>
            <p className={`${style.designSectionHeaderDescription}`}>{description}</p>
            <p className={`${style.designSectionHeaderShort}`}>{short}</p>
          </div>
          <OutlineButton onClick={onExploreClick}>Explore</OutlineButton>
        </div>
        <div className={`${style.designSectionBody} ${bodyClassname}`}>{children}</div>
      </div>
    </section>
  )
}

export default HomeDesignSection
