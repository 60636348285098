import Image from 'next/image'
import styled from 'styled-components'
import arrowRightImg from '../../assets/arrowRightPink.svg'

export const ActionButtonArrow = styled(Image)``

export const OutlineButtonArrowContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;

  @media (min-width: 1201px) {
    top: 13px;
    right: 19px;
  }
`

export const OutlineButtonStyle = styled.button`
  position: relative;
  outline: none;
  background: transparent;
  border: 2px solid #ff5e6d;
  border-radius: 33px;
  padding: 6px 28px 6px 12px;
  font-weight: 700;
  font-size: 12px;
  color: #ff5e6d;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: #ff5e6d;
    color: white;

    ${ActionButtonArrow} {
      filter: invert(100%) sepia(0%) saturate(300%) hue-rotate(100deg) brightness(200%) contrast(100%);
    }
  }

  @media (min-width: 1201px) {
    font-size: 18px;
    padding: 12px 40px 12px 27px;
  }
`

export const OutlineButton = ({ children, ...props }) => {
  return (
    <OutlineButtonStyle {...props}>
      {children}
      <OutlineButtonArrowContainer>
        <ActionButtonArrow src={arrowRightImg} width={8} height={14} alt="" />
      </OutlineButtonArrowContainer>
    </OutlineButtonStyle>
  )
}

export const GradientButton = styled.button`
  padding: 0 32px;
  height: 42px;
  border-radius: 25px;
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  border: 0;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 769px) {
    font-size: 18px;
    padding: 0 40px;
    height: 50px;
  }
`

export const SolidButton = styled.button`
  padding: 0 32px;
  height: 42px;
  border-radius: 25px;
  background: #ff5e6d;
  border: 0;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;

  font-size: 18px;
  padding: 0 40px;
  height: 50px;
`

export const SolidDivButton = styled.div`
  padding: 0 32px;
  height: 42px;
  border-radius: 25px;
  background: #ff5e6d;
  border: 0;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;

  font-size: 18px;
  padding: 0 40px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
