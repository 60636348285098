import HomeDesignSection from './HomeDesignSection'
import DecorateMyRoomCard from '../../../components/DesignerDesigns/cards/DecorateMyRoomCard'

const HomeDecorate = props => {
  const featuresList = [{ type: 'room' }, { type: 'decor' }, { type: 'background' }, { type: 'ar' }, { type: 'ruler' }, { type: '3d' }, { type: 'becomeMember' }]

  return (
    <HomeDesignSection title="Decorate Real Rooms" description="Upload your own items and rooms to create unique designs. Upgrade your design capabilities with premium tools." short="Upload your spaces and items." {...props}>
      {featuresList.map((d, i) => (
        <DecorateMyRoomCard key={`decorate-${d.type}`} data={d} {...props} />
      ))}
    </HomeDesignSection>
  )
}

export default HomeDecorate
