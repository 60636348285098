import Image from 'next/image'
import {
  GradientButton,
  ReviewDesktopOnlyBlock,
  ReviewAuthor,
  ReviewBlock,
  ReviewsFlex,
  ReviewsList,
  ReviewsSection,
  StarsImage,
  MobileOnlyBlock,
  MobileViewMoreSection,
  AppStoreReviewBlock,
  MoreButton,
  AdaptibleIosImage,
  ReviewPageSectionContainer
} from '../Create.styles'
import reviewsImg from '../../../assets/seo/reviews.svg'
import fiveStarsImg from '../../../assets/seo/fiveStars.svg'
import appleStoreImage from '../../../assets/seo/AppleStoreImage.avif'

const HomeReview = () => {
  return (
    <ReviewPageSectionContainer>
      <ReviewsSection>
        <h2>Our Users Love Our Interior Design App</h2>
        <ReviewsFlex>
          <ReviewsList>
            <MobileOnlyBlock>
              <AppStoreReviewBlock>
                <img src={reviewsImg} alt="" />
                <MoreButton>More</MoreButton>
              </AppStoreReviewBlock>
            </MobileOnlyBlock>
            <ReviewBlock>
              <ReviewAuthor>-coola</ReviewAuthor>
              <h3>Therapy app!</h3>
              <StarsImage>
                <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
              </StarsImage>
              <p>
                I’ve been a member since April 2021. DecorMatters is my favorite “therapy” app. I love the opportunity to be creative, view the incredible designs from others and mostly because of the encouraging community of designers. Best interior
                design app!
              </p>
            </ReviewBlock>
            <ReviewBlock>
              <ReviewAuthor>-Itachixxxxx</ReviewAuthor>
              <h3>Cool app!</h3>
              <StarsImage>
                <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
              </StarsImage>
              <p>
                This app is so freaking cool! I had my eyes on a sofa from Pottery Barn for the longest time, but I wasn’t sure if it would match with my current living room. My wife and I were really frustrated because we did not want to spend that
                much money on something that we would end up hating. So, I went on the app store and did some searching. I came across DecorMatters and to my surprise, I was so happy and grateful. The augmented reality option makes my life SO much
                easier because I can finally be able to design my room just the way I like it without having to run back and forth from the furniture store lol!!! If you guys are looking for a good room design app, be sure to check out this one
                because it is totally worth it.
              </p>
            </ReviewBlock>
            <ReviewBlock>
              <ReviewAuthor>-IIwDr.Remulac</ReviewAuthor>
              <h3>Love this app!</h3>
              <StarsImage>
                <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
              </StarsImage>
              <p>
                What’s not to love about this app?! I’ve been using this app for over 1 year now and still haven’t grown tired of playing. Every challenge is unique and fun and even though most challenges have certain requirements it still gives you
                enough options to make it your own. The game is perfectly done as far as animations and the furniture looks identical to its real life versions and any piece you like has a direct link to the website so you can see and possibly buy
                the piece of furniture in real life.
              </p>
            </ReviewBlock>
          </ReviewsList>
          <ReviewDesktopOnlyBlock as="a" target="_blank" href="https://apps.apple.com/us/app/decor-matters-design-shop/id1168709467">
            <Image width={553} height={714} src={'https://cdn.decormatters.com/assets/lead_design.webp'} />
          </ReviewDesktopOnlyBlock>
          <AdaptibleIosImage />
        </ReviewsFlex>
        <MobileViewMoreSection as="a" target="_blank" href="https://apps.apple.com/us/app/decor-matters-design-shop/id1168709467">
          <GradientButton>Download iOS App</GradientButton>
        </MobileViewMoreSection>
      </ReviewsSection>
    </ReviewPageSectionContainer>
  )
}

export default HomeReview
