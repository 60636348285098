import styled from 'styled-components'

export const TemplateCardWrapper = styled.div`
  position: relative;
  background: none;
  border: none;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.1));
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;

  @media (max-width: 1200px) {
    width: 240px;
    height: 240px;
  }

  @media (max-width: 800px) {
    width: 140px;
    height: 140px;
  }
`

export const ButtonsOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`

export const CreateDesignButton = styled.div`
  user-select: none;
  text-decoration: none;
  text-align: center;
  padding: 8px 20px;
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
  color: white;
  background-color: #ff5e6d;
  border: 2px solid #ff5e6d;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 800px) {
    max-width: 95%;
    width: 110px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 0;
  }
`
