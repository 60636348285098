import MyEventNewTagImg from '../../../assets/images/myEventNewTag.svg'
import { useDispatch } from 'react-redux'
import Image from 'next/image'
import style from './myevent.module.css'
import { GradientButton } from '../../../components/common/Buttons2024'

export const HomeMyEvent = props => {
  const dispatch = useDispatch()

  return (
    <div className={`${style.homeMyeventWrapper}`}>
      <div className={`${style.homemyeventcontainer}`}>
        <div className={`${style.homeContentContainer}`}>
          <Image src={MyEventNewTagImg} width={95} height={50} />
          <h2>Design Your Dream Room in Just One Click!</h2>
          <p>
            <strong>Get your dream room today for as low as $10!</strong> Post your vision and instantly receive a variety of inspiring design ideas from our community. It's quick, fun, and risk-free!
          </p>
          {/*  <div>
            <GradientButton
            onClick={() => {
              dispatch(gotoLogIn())
            }}
          >
            Host Events
          </GradientButton> 
          </div>*/}
          <div className={`${style.homeMyeventButtonContainer}`}>
            <GradientButton onClick={props.onExploreClick}>Host Events</GradientButton>
          </div>
        </div>
        <div className={`${style.homeImageContainer}`}>
          <Image src="https://cdn.decormatters.com/assets/myevent_banner.png" width={478} height={346} />
        </div>
      </div>
    </div>
  )
}
