import HomeDesignSection from './HomeDesignSection'
import TemplateCard from '../../../components/TemplateCard'

const HomeTemplates = props => {
  const templatesList = props?.templates?.slice(0, 3) || []

  return (
    <HomeDesignSection title="Design on Templates" description="Virtually place furniture onto templates. Decorate with millions of home decor pieces from 30+ furniture brands." short="Virtually try furniture pieces and ideas." {...props}>
      {templatesList.map((d, i) => (
        <TemplateCard key={`template-${i}`} data={d} onClick={props.onExploreClick} {...props} />
      ))}
    </HomeDesignSection>
  )
}

export default HomeTemplates
