import styled from 'styled-components'

export const SignUpCtaWrapper = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  padding: 48px 45px 22px;
  background: #f7f5f5;
  display: grid;
  grid-auto-columns: repeat(2, 1fr);
  grid-auto-flow: column;

  grid-gap: 25px;

  @media only screen and (max-width: 743px) {
    padding: 15px;
    display: ${props => (props.hideOnMobile ? 'none' : 'block')};
  }

  @media (min-width: 1728px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (min-width: 2560px) {
    padding-left: 180px;
    padding-right: 180px;
  }

  @media (max-width: 1024px) {
    padding-left: 30px;
  }

  @media (max-width: 800px) {
    padding-left: 11px;
  }
`

export const CtaHeaderTitle = styled.h1`
  // font-size: 36px;
  // line-height: 48px;
  // margin: 0 0 35px 0;
  padding: 0;
  margin: 0 0 1.9vw 0;
  font-family: Helvetica Neue;
  font-size: 1.9vw;
  line-height: 2.5vw;
  font-weight: 700;
  letter-spacing: 0.01em;
  max-width: 650px;

  white-space: nowrap;

  @media only screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media only screen and (max-width: 743px) {
    margin: 0 0 12px 0;
    max-width: 340px;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.01em;
  }
`

export const QuestList = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 1.9vw;

  @media only screen and (max-width: 600px) {
    margin-bottom: 16px;
    white-space: nowrap;
  }
`

export const QuestCheckboxItem = styled.li`
  // font-size: 20px;
  // line-height: 36px;
  padding: 0;
  font-family: Helvetica Neue;
  font-size: 1.2vw;
  line-height: 2vw;
  font-weight: 500;
  letter-spacing: 0.01em;

  margin-left: 16px;
  margin-right: 16px;

  @media only screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 36px; /* 200% */
    letter-spacing: 0.18px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 36px; /* 200% */
    letter-spacing: 0.16px;
  }

  @media only screen and (max-width: 743px) {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.01em;
  }
`

export const SignUpButton = styled.button`
  background: linear-gradient(
    270deg,
    #ff9d74 0%,
    #ff5e6d 37.4%,
    #ff4f7e 74.69%,
    #ff6d90 100%
  );

  border-radius: 1.6vw;
  width: 14.5vw;
  height: 3.2vw;
  border: none;
  color: #ffffff;
  font-family: Helvetica Neue;
  font-size: 1.1vw;
  font-weight: 700;
  letter-spacing: 0em;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  white-space: nowrap;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      linear-gradient(
        270deg,
        #ff9d74 0%,
        #ff5e6d 37.4%,
        #ff4f7e 74.69%,
        #ff6d90 100%
      );
  }

  @media (max-width: 1024px) {
    width: 200px;
    height: 48px;
    border-radius: 37px;
    font-size: 16px;
  }

  @media (max-width: 743px) {
    height: 42px;
    width: 180px;
    border-radius: 37px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    margin: auto;
  }
`

export const AppStoreLink = styled.a`
  position: absolute;
  bottom: 24px;
  right: 24px;
  line-height: 1;
  z-index: 2;

  & img {
    width: 133px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`

export const Columns = styled.div`
  display: flex;
  gap: 25px;
  gap: 2.5vw;
  @media only screen and (max-width: 743px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
