import { useState } from 'react'
import styled from 'styled-components'

import moveIcon from '../../../../assets/beforeafter-icon.svg'
import Image from 'next/image'

const Root = styled.div`
  position: relative;
  width: 100%;
  max-width: 550px;
  aspect-ratio: 550 / 410;
  border-radius: 20px 100px 20px 20px;
  overflow: hidden;
  margin: 0 20px;

  @media (min-width: 1012px) {
    margin: 0;
  }
  /*
  @media (max-width: 1000px) {
    width: 45vw;
    border-radius: 10px 100px 10px 10px;
  }
  @media (max-width: 743px) {
    border-radius: 10px 60px 10px 10px;
    width: 95vw;
  }
  */
`

const BeforeImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // width: 300px;
`

const Img = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 550px 410px;
  background-repeat: no-repeat;
`

const Slider = styled.input`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  margin: 0;
  transition: all 0.2s;

  &::-webkit-slider-thumb {
    // width: 9px;
    -webkit-appearance: none;
    appearance: none;
    width: 4px;
    height: 100vh;
    background: white;
    cursor: pointer;
    /*
    @media (max-width: 800px) {
      width: 4px;
    }
    */
  }
  &::-moz-range-thumb {
    width: 4px;
    height: 100vh;
    background: white;
    cursor: pointer;
    /*
    @media (max-width: 800px) {
      width: 4px;
    }
    */
  }
`

const SliderButton = styled.div`
  // width: 35px;
  // height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  background-color: white;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 1012px) {
    height: 26px;
    width: 26px;
  }
  /*
  @media (max-width: 1350px) {
    & img {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    width: 15px;
    height: 15px;
  }
  */
`

const maxValue = 200
const BeforeAfter = () => {
  const [progress, setProgress] = useState(maxValue / 4.5)
  const position = (progress / maxValue) * 100

  const handleSlide = e => {
    setProgress(e.target.value)
  }

  return (
    <Root>
      <Image src={'https://cdn.decormatters.com/assets/fb950a5c59465d00c448898be072404a_w750.webp'} layout="fill" objectFit="cover" priority />
      <BeforeImage
        style={{
          clipPath: `polygon(0px 0px, ${position}% 0px, ${position}% 100%, 0px 100%)`,
          backgroundImage: `url(https://cdn.decormatters.com/assets/fd4a7413a8e32466a1676587822b112d_w750.webp)`
        }}
      />
      <Slider type="range" min="1" max={maxValue} value={progress} onChange={handleSlide} />
      <SliderButton style={{ left: `calc(${position}% - 0.07vw)` }}>
        <Image width={38} height={21} src={moveIcon} />
      </SliderButton>
    </Root>
  )
}

export default BeforeAfter
