import Image from 'next/image'
import { MobileTitleContainer, MobileNote, BackgroundImageMobile, MobileContentContainer, MobileMembershipBlockWrapper, MobileTitle } from './MembershipLandingAdvert.styles'
import membershipBadge from '../../assets/badge_membership_on.png'
import membershipMobileBgImage from '../../assets/seo/MembershipBGMobile.png'
import MobileMembersipPackages from './MobileMembersipPackages'
import MobileMembershipPerks from './MobileMembershipPerks'
import { FeatureSlider } from '../MembershipModal/components/FeatureSlider'

export const MobileMembershipSection = props => {
  return (
    <MobileMembershipBlockWrapper>
      <BackgroundImageMobile src={membershipMobileBgImage} layout="fill" />
      <MobileContentContainer>
        <MobileTitleContainer>
          <Image src={membershipBadge} width="40" height="40" />
          <MobileTitle>DecorMatters</MobileTitle>
        </MobileTitleContainer>
        <FeatureSlider showTitle={false} />
        {/* <MobileMembershipPerks {...props} /> */}
        <MobileMembersipPackages {...props} />
        <MobileNote>Buy with Confidence: Cancel at anytime</MobileNote>
      </MobileContentContainer>
    </MobileMembershipBlockWrapper>
  )
}
