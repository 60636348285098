import Image from 'next/image'
import { ShoppingSectionContainer, GradientButton, DesktopOnlyBlock, ProductImage, SeoLogoCard, SeoSectionInfo, SeoSectionLogos, ShoppingSection } from '../Create.styles'
import createProductImage from '../../../assets/seo/createProductImage.avif'
import crateBarrelLogoImg from '../../../assets/seo/crate_barrel.png'
import cb2LogoImg from '../../../assets/seo/cb2.png'
import articleLogoImg from '../../../assets/seo/Article.png'
import westElmLogoImg from '../../../assets/seo/Westelm.png'
import ikeaLogoImg from '../../../assets/seo/Ikea.png'
import wayfairLogoImg from '../../../assets/seo/wayfair.png'
import pbkLogoImg from '../../../assets/seo/pbk.png'
import dwrLogoImg from '../../../assets/seo/DWR.png'
import rhLogoImg from '../../../assets/seo/RH.png'

const HomeShopping = () => {
  return (
    <ShoppingSectionContainer>
      <ShoppingSection>
        <DesktopOnlyBlock>
          <ProductImage>
            <Image width={407} height={527} src={'https://cdn.decormatters.com/assets/rating.webp'} alt="The Leading Home Design App That Makes Shopping Enjoyable" />
          </ProductImage>
        </DesktopOnlyBlock>
        <SeoSectionInfo>
          <h2>The Leading Home Design App That Makes Shopping Enjoyable</h2>
          <p>
            DecorMatters is the top ranked platform to design and shop furniture brands and retailers. A relaxing room design app where you can virtually try items in your home before making a purchase. With millions of pieces from over 30 popular
            furniture brands in our home design app, interior design has never been more convenient and smart. You can also decorate with your own personal items to visualize side by side with other brands.
          </p>
          <SeoSectionLogos>
            <SeoLogoCard>
              <img src={crateBarrelLogoImg} loading="lazy" alt="Crate & Barrel" />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={cb2LogoImg} loading="lazy" alt="CB2" width={97} height={37} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={articleLogoImg} loading="lazy" alt="Article" width={97} height={36} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={westElmLogoImg} loading="lazy" alt="West Elm" width={97} height={34} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={ikeaLogoImg} loading="lazy" alt="IKEA" width={97} height={39} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={wayfairLogoImg} loading="lazy" alt="Wayfair" width={97} height={35} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={pbkLogoImg} loading="lazy" alt="Pottery Barn Kids" width={97} height={35} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={dwrLogoImg} loading="lazy" alt="Design Within Reach" width={97} height={42} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img width={43} height={24} src={rhLogoImg} loading="lazy" alt="Restoration Hardware" />
            </SeoLogoCard>
          </SeoSectionLogos>
          <GradientButton
            onClick={() => {
              openLogin()
              logEvent('start_designing_click', {})
            }}
          >
            Start Designing
          </GradientButton>
        </SeoSectionInfo>
      </ShoppingSection>
    </ShoppingSectionContainer>
  )
}

export default HomeShopping
