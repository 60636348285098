import style from './HomeExplore/explore.module.css'
import HomeDesignSection from './HomeDesignSection'
import { InspirationItem } from './HomeExplore/InspirationItem'

const HomeExplore = props => {
  const inspirationsList = props.inspirations.slice(0, 5)

  return (
    <HomeDesignSection
      title="Explore our Community"
      description="Join our interior design app community of 6 million users to find interior design inspiration and share creative home decoration ideas."
      short="Join 10+ million creative designers now."
      bodyClassname={`${style.homeExploreBody}`}
      {...props}
    >
      <div className={`${style.homeMasonary}`}>
        {inspirationsList.map(item => (
          <InspirationItem key={item.objectId} sourcePost={item} user={item.user} likes={item.numLikes} hideActionButton />
        ))}
      </div>
    </HomeDesignSection>
  )
}

export default HomeExplore
