import React, { useEffect, useState } from 'react'
import DecorateMyRoomCard from '../../components/DesignerDesigns/cards/DecorateMyRoomCard'
import { useDispatch, useSelector } from 'react-redux'
import { gotoLogIn } from '../../redux/actions/AuthActions'
import { InspirationItem } from '../../components/Feed'
import {
  AsSeenOnBlock,
  CommunityPostsWrapper,
  CreatePageSection,
  CreatePageWrapper,
  GradientButton,
  DesktopOnlyBlock,
  ProductImage,
  RegisterSuggestionContainer,
  ReviewAuthor,
  ReviewBlock,
  ReviewsFlex,
  ReviewsList,
  ReviewsSection,
  SeoLogoCard,
  SeoSectionInfo,
  SeoSectionLogos,
  ShoppingSection,
  StarsImage,
  MobileOnlyBlock,
  MobileViewMoreSection,
  AppStoreReviewBlock,
  MoreButton,
  AdaptibleIosImage
} from './Create.styles'
import createAccountBgImg from '../../assets/createAccountBg.png'
import SuggestRegisterScroll from '../../components/register/SuggestRegisterScroll'
import Image from 'next/image'
import { useIsMobile } from '../../hooks/useIsMobile'
import SignUpCta from '../../components/SignUpCta'
import BlogFooter from '../../components/blog/Footer'
import dynamic from 'next/dynamic'
import { setNavbar } from '../../redux/designerReducers/designerReducer'
import { NAV } from '../../redux/constants'
import TemplatesCategory from '../../components/TemplatesCategory'
import ChallengeCard from '../../components/ChallengeCard'
import TemplateCard from '../../components/TemplateCard'
import { MasonryGrid } from '../../components/Feed/Feed.styles'
import adLogoImg from '../../assets/seen-on-logos/ad-logo.png'
import atLogoImg from '../../assets/seen-on-logos/at-logo.png'
import biLogoImg from '../../assets/seen-on-logos/bi-logo.png'
import cbLogoImg from '../../assets/seen-on-logos/cb-logo.png'
import ftLogoImg from '../../assets/seen-on-logos/ft-logo.png'
import hgLogoImg from '../../assets/seen-on-logos/hg-logo.png'
import mittrLogoImg from '../../assets/seen-on-logos/mittr-logo.png'
import rcLogoImg from '../../assets/seen-on-logos/rc-logo.png'
import yLogoImg from '../../assets/seen-on-logos/y-logo.png'
import createProductImage from '../../assets/seo/createProductImage.avif'
import appleStoreImage from '../../assets/seo/AppleStoreImage.avif'
import crateBarrelLogoImg from '../../assets/seo/crate_barrel.png'
import cb2LogoImg from '../../assets/seo/cb2.png'
import articleLogoImg from '../../assets/seo/Article.png'
import westElmLogoImg from '../../assets/seo/Westelm.png'
import ikeaLogoImg from '../../assets/seo/Ikea.png'
import wayfairLogoImg from '../../assets/seo/wayfair.png'
import pbkLogoImg from '../../assets/seo/pbk.png'
import dwrLogoImg from '../../assets/seo/DWR.png'
import rhLogoImg from '../../assets/seo/RH.png'
import fiveStarsImg from '../../assets/seo/fiveStars.svg'
import MembershipLandingAdvert from '../../components/MembershipLandingAdvert'
import reviewsImg from '../../assets/seo/reviews.svg'
import MyEventBannerSection from '../../components/MyEventBannerSection'
import { fetchDesignGames } from '../../redux/designerReducers/designGamesReducer'
import { clearFilters } from '../../redux/designerReducers/itemsReducer'
import { setSelectedBubble } from '../../redux/designerReducers/canvasReducer'
import { useIsTablet } from '../../hooks/useIsTablet'
import HomeTitle from './components/HomeTitle'
import Home from './components/Home'

const DesignerApp = dynamic(() => import('../Designs'), {
  ssr: false
})

const FeatureModal = dynamic(() => import('../../components/DesignerModals/FeatureModal'), {
  ssr: false
})

export const CreatePage = props => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const [featureModalData, setFeatureModalData] = useState(null)
  const { dailyTasks, welcomeTasks } = useSelector(state => state.checkIn)
  const userLoading = useSelector(state => state.profile.loading)

  const openLogin = () => {
    dispatch(gotoLogIn())
  }

  //const challengesList = isMobile ? challenges.slice(0, 3) : challenges
  //const templatesList = isMobile ? templates.slice(0, 3) : templates
  //const featuresList = [{ type: 'room' }, { type: 'decor' }, { type: 'background' }, { type: 'ar' }, { type: 'ruler' }, { type: '3d' }, { type: 'becomeMember' }]
  //const inspirationsList = isMobile ? inspirations.slice(0, 3) : inspirations

  useEffect(() => {
    dispatch(setNavbar(NAV))
    dispatch(clearFilters())
    dispatch(setSelectedBubble(null))

    dispatch(fetchDesignGames())
  }, [])

  if (isAuthenticated) {
    if (userLoading) return null

    if (dailyTasks?.length === 0 || welcomeTasks?.length === 0) return null

    return <DesignerApp />
  }

  return <Home onExploreClick={openLogin} {...props} />
  return (
    <>
      <HomeTitle />
      {/* <SignUpCta /> */}
      <CreatePageWrapper>
        {/* <TemplatesCategory
          title="Play Design Games"
          subtitleDesktop="Participate in exciting design challenges to earn rewards and recognition, sharpen your interior design skills."
          subtitleMobile="Publish designs to earn rewards."
          data={challengesList}
          cardComponent={ChallengeCard}
          cardProps={{ onClickMore: openLogin, onClickJoin: openLogin }}
          buttonTitle="Explore"
          buttonAction={openLogin}
          buttonShow={!isMobile && !isTablet}
        />
        <TemplatesCategory
          title="Decorate Real Rooms"
          subtitleDesktop="Upload your own items and rooms to create unique designs, upgrade your design capabilities with premium tools and items."
          subtitleMobile="Upload your items and rooms."
          data={featuresList}
          cardComponent={DecorateMyRoomCard}
          cardProps={{ setModalData: setFeatureModalData }}
          buttonTitle="Explore"
          buttonAction={openLogin}
          buttonShow={!isMobile && !isTablet}
        />
        <TemplatesCategory
          title="Design on Templates"
          subtitleDesktop="Virtually place furniture onto templates, decorate with millions of home decor pieces from 30+ furniture brands."
          subtitleMobile="Virtually try furniture pieces and ideas."
          data={templatesList}
          cardComponent={TemplateCard}
          cardProps={{ onClick: openLogin }}
          buttonTitle="Explore"
          buttonAction={openLogin}
          buttonShow={!isMobile && !isTablet}
        />
        <TemplatesCategory title="Explore our Community" subtitleDesktop="Join our interior design app community of 10 million users to find interior design inspiration and share creative home decoration ideas." subtitleMobile="Join 10 million users and find interior design inspiration." buttonTitle="Explore" buttonAction={openLogin} buttonShow={!isMobile && !isTablet}>
          <CommunityPostsWrapper>
            <MasonryGrid static>
              {inspirationsList.map(item => (
                <InspirationItem key={item.objectId} sourcePost={item} user={item.user} likes={item.numLikes} hideActionButton />
              ))}
            </MasonryGrid>
            <MobileViewMoreSection>
              <GradientButton>View More</GradientButton>
            </MobileViewMoreSection>
          </CommunityPostsWrapper>
        </TemplatesCategory> */}
        {/* <MyEventBannerSection /> */}
        {/* <AsSeenOnBlock>
          <span>As Seen On...</span>
          <Image src={biLogoImg} width={79} height={32} alt="Business Insider" />
          <Image src={mittrLogoImg} width={76} height={38} alt="MIT Technology Review" />
          <Image src={atLogoImg} width={114} height={41} alt="Apartment Therapy" />
          <Image src={adLogoImg} width={110} height={40} alt="Architectural Digest" />
          <Image src={hgLogoImg} width={96} height={37} alt="House & Garden" />
          <Image src={ftLogoImg} width={132} height={31} alt="Furniture Today" />
          <Image src={rcLogoImg} width={104} height={19} alt="realtor.com" />
          <Image src={cbLogoImg} width={84} height={25} alt="Coldwell Banker" />
          <Image src={yLogoImg} width={97} height={26} alt="Yahoo" />
        </AsSeenOnBlock> */}
        {/* <MembershipLandingAdvert setFeatureModalData={setFeatureModalData} /> */}
        {/* <ShoppingSection>
          <DesktopOnlyBlock>
            <ProductImage>
              <Image width={800} height={1008} src={createProductImage} alt="The Leading Home Design App That Makes Shopping Enjoyable" />
            </ProductImage>
          </DesktopOnlyBlock>
          <SeoSectionInfo>
            <h2>The Leading Home Design App That Makes Shopping Enjoyable</h2>
            <p>
              DecorMatters is the top ranked platform to design and shop furniture brands and retailers. A relaxing room design app where you can virtually try items in your home before making a purchase. With millions of pieces from over 30 popular furniture brands in our home design app, interior design has never been more convenient and smart. You can also decorate with your own personal items
              to visualize side by side with other brands.
            </p>
            <SeoSectionLogos>
              <SeoLogoCard>
                <img src={crateBarrelLogoImg} loading="lazy" alt="Crate & Barrel" />
              </SeoLogoCard>
              <SeoLogoCard>
                <img src={cb2LogoImg} loading="lazy" alt="CB2" width={97} height={37} />
              </SeoLogoCard>
              <SeoLogoCard>
                <img src={articleLogoImg} loading="lazy" alt="Article" width={97} height={36} />
              </SeoLogoCard>
              <SeoLogoCard>
                <img src={westElmLogoImg} loading="lazy" alt="West Elm" width={97} height={34} />
              </SeoLogoCard>
              <SeoLogoCard>
                <img src={ikeaLogoImg} loading="lazy" alt="IKEA" width={97} height={39} />
              </SeoLogoCard>
              <SeoLogoCard>
                <img src={wayfairLogoImg} loading="lazy" alt="Wayfair" width={97} height={35} />
              </SeoLogoCard>
              <SeoLogoCard>
                <img src={pbkLogoImg} loading="lazy" alt="Pottery Barn Kids" width={97} height={35} />
              </SeoLogoCard>
              <SeoLogoCard>
                <img src={dwrLogoImg} loading="lazy" alt="Design Within Reach" width={97} height={42} />
              </SeoLogoCard>
              <SeoLogoCard>
                <img width={43} height={24} src={rhLogoImg} loading="lazy" alt="Restoration Hardware" />
              </SeoLogoCard>
            </SeoSectionLogos>
            <GradientButton
              onClick={() => {
                openLogin()
                logEvent('start_designing_click', {})
              }}
            >
              Start Designing
            </GradientButton>
          </SeoSectionInfo>
        </ShoppingSection> */}
        <ReviewsSection>
          <h2>Our users are loving our interior design app</h2>
          <ReviewsFlex>
            <ReviewsList>
              <MobileOnlyBlock>
                <AppStoreReviewBlock>
                  <img src={reviewsImg} alt="" />
                  <MoreButton>More</MoreButton>
                </AppStoreReviewBlock>
              </MobileOnlyBlock>
              <ReviewBlock>
                <ReviewAuthor>-coola</ReviewAuthor>
                <h3>Therapy app!</h3>
                <StarsImage>
                  <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
                </StarsImage>
                <p>
                  I’ve been a member since April 2021. DecorMatters is my favorite “therapy” app. I love the opportunity to be creative, view the incredible designs from others and mostly because of the encouraging community of designers. Best
                  interior design app!
                </p>
              </ReviewBlock>
              <ReviewBlock>
                <ReviewAuthor>-Itachixxxxx</ReviewAuthor>
                <h3>Cool app!</h3>
                <StarsImage>
                  <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
                </StarsImage>
                <p>
                  This app is so freaking cool! I had my eyes on a sofa from Pottery Barn for the longest time, but I wasn’t sure if it would match with my current living room. My wife and I were really frustrated because we did not want to spend
                  that much money on something that we would end up hating. So, I went on the app store and did some searching. I came across DecorMatters and to my surprise, I was so happy and grateful. The augmented reality option makes my life SO
                  much easier because I can finally be able to design my room just the way I like it without having to run back and forth from the furniture store lol!!! If you guys are looking for a good room design app, be sure to check out this
                  one because it is totally worth it.
                </p>
              </ReviewBlock>
              <ReviewBlock>
                <ReviewAuthor>-IIwDr.Remulac</ReviewAuthor>
                <h3>Love this app!</h3>
                <StarsImage>
                  <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
                </StarsImage>
                <p>
                  What’s not to love about this app?! I’ve been using this app for over 1 year now and still haven’t grown tired of playing. Every challenge is unique and fun and even though most challenges have certain requirements it still gives
                  you enough options to make it your own. The game is perfectly done as far as animations and the furniture looks identical to its real life versions and any piece you like has a direct link to the website so you can see and possibly
                  buy the piece of furniture in real life.
                </p>
              </ReviewBlock>
            </ReviewsList>
            <DesktopOnlyBlock as="a" target="_blank" href="https://apps.apple.com/us/app/decor-matters-design-shop/id1168709467">
              <Image width={645} height={828} src={appleStoreImage} />
            </DesktopOnlyBlock>
            <AdaptibleIosImage />
          </ReviewsFlex>
          <MobileViewMoreSection as="a" target="_blank" href="https://apps.apple.com/us/app/decor-matters-design-shop/id1168709467">
            <GradientButton>Download iOS App</GradientButton>
          </MobileViewMoreSection>
        </ReviewsSection>
      </CreatePageWrapper>
      <CreatePageSection signUpSuggestion mobileOnly>
        <RegisterSuggestionContainer>
          <Image src={createAccountBgImg} layout="responsive" width="400px" height="300px" alt="Create account" />
          <SuggestRegisterScroll />
        </RegisterSuggestionContainer>
      </CreatePageSection>
      {featureModalData && <FeatureModal data={featureModalData} setData={setFeatureModalData} />}
      <BlogFooter />
    </>
  )
}
