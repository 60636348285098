import HomeDesignSection from './HomeDesignSection'
import ChallengeCard from '../../../components/ChallengeCard'

const HomeGames = props => {
  const challengesList = props?.challenges?.slice(0, 3) || []

  return (
    <HomeDesignSection title="Play Design Games" description="Participate in exciting decorating games to earn rewards and recognition. Sharpen your interior design skills." short="Publish designs to earn rewards." {...props}>
      {challengesList.map((d, i) => (
        <ChallengeCard key={`game-${i}`} data={d} onClickMore={props.onExploreClick} onClickJoin={props.onExploreClick} {...props} />
      ))}
    </HomeDesignSection>
  )
}

export default HomeGames
