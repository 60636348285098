export const addRouteType = route => ({
  currentRoute: route
})

export const addDisableHeaderShadow = (disableHeaderShadow = true) => ({
  disableHeaderShadow
})

export const addShowMobileBackButton = (showMobileBackButton = true) => ({
  showMobileBackButton
})

export const addSecondaryMenu = menu => ({
  headerMenu: menu
})

export const addDisableOverscroll = () => ({
  disableOverscroll: true
})
