import { useState, useRef } from 'react'
import style from './HomeTitle/title.module.css'
import BeforeAfter from './HomeTitle/BeforeAfter'
import Image from 'next/image'
import Link from 'next/link'
import { SolidDivButton } from '../../../components/common/Buttons2024'
import CropRoomModal from '../../../components/DesignerModals/CropRoomModal'
import { useDecor } from '../../../context/decor'

const preselects = [
  { thumb: '7efc6785ad19cccb8c508d88f51e92da_w100.webp', original: '7efc6785ad19cccb8c508d88f51e92da.png' },
  { thumb: '97b236f4a1923c7bca37a4f2d3648a9b_w100.webp', original: '97b236f4a1923c7bca37a4f2d3648a9b.png' },
  { thumb: '8bc21da9ba94403f8b5c699ab96a715a_w100.webp', original: '8bc21da9ba94403f8b5c699ab96a715a.jpeg' },
  { thumb: 'a6589b9e7c6c930dc83c443c8e92fabb_w100.webp', original: 'a6589b9e7c6c930dc83c443c8e92fabb.png' }
]

const mimeTypes = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  gif: 'image/gif'
}

const HomeTitle = ({ onExploreClick, ...props }) => {
  const accept = ['png', 'jpeg', 'jpg', 'webp']
  const inputId = 'homeupload'

  var temp = []

  if (Array.isArray(accept)) {
    accept.forEach(f => {
      if (mimeTypes[f]) temp.push(mimeTypes[f])
    })

    temp.join(',')
  }

  const rInput = useRef()
  const rCrop = useRef()

  const { setHomeMyRoomUploadImageFile } = useDecor()
  const [showCropModal, setShowCropModal] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [acceptTypes, setAcceptTypes] = useState(temp)

  const readFileAsync = file => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const cropAndPrepare = async file => {
    const content = await readFileAsync(file)
    onCrop(content)
    rInput.current.value = null
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDrop = async e => {
    e.preventDefault()
    e.stopPropagation()

    setDragging(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      await cropAndPrepare(e.dataTransfer.files[0])
    }
    //if (onExploreClick) onExploreClick()
  }

  const handleImage = async e => {
    if (e.target.files && e.target.files[0]) {
      await cropAndPrepare(e.target.files[0])
      // const files = e.target.files
      // const content = await readFileAsync(files[0])
      // console.log(content)
      // onCrop(content)
      // rInput.current.value = null
      //if (onChange) onChange(content)
      //const image = new Image()
      //image.src = content
      //await image.decode()
      //imageInfo(files[0], image)
    }
  }

  const handlePresetImage = async imageFilename => {
    var result = await fetch(`https://cdn.decormatters.com/assets/${imageFilename}`)
    result = await result.blob()
    const resultAsFile = new File([result], 'myroom.jpeg', { type: 'image/jpeg' })
    cropAndPrepare(resultAsFile)
  }

  const onCrop = img => {
    rCrop.current.setImage(img)
    setShowCropModal(true)
  }

  const handleCropComplete = croppedImageFile => {
    setShowCropModal(false)
    setHomeMyRoomUploadImageFile(croppedImageFile)
    if (onExploreClick) onExploreClick()
  }

  const handleCropClose = () => {
    setShowCropModal(false)
  }

  // return (
  //   <div className={`${style.fullscreenTakeover}`}>
  //     sdfdsf
  //   </div>
  // )
  return (
    <div className={`${style.homeTitleWrapper}`}>
      <CropRoomModal ref={rCrop} show={showCropModal} onCompleteForDelayedSave={handleCropComplete} onClose={handleCropClose} />
      <input ref={rInput} className={`${style.homeHiddenUploadInput}`} id={inputId} name={inputId} type="file" accept={acceptTypes} onChange={handleImage} />
      <div className={`${style.homeTitleContainer}`}>
        <div className={`${style.homeTitleDestop}`}>
          <h1>DecorMatters Home Design App & Decorating Game</h1>
          <h2>Play Design Games, Decorate Real Rooms, Find Inspiration, and Unleash Your Inner Designer.</h2>
        </div>
        <div className={`${style.homeTitleContentContainer}`}>
          <div className={`${style.homeTitleContentRevealer}`}>
            <BeforeAfter />
          </div>
          <div className={`${style.homeTitleMobile}`}>
            <h1>DecorMatters Home Design App & Decorating Game</h1>
            <h2>Play Design Games, Decorate Real Rooms, Find Inspiration, and Unleash Your Inner Designer.</h2>
          </div>
          <div className={`${style.homeTitleContentUploader}`}>
            <h2>Upload Your Room</h2>
            <div className={`${style.homeTitleContentDropZone}`} dragging={dragging.toString()} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
              <label htmlFor={inputId}>
                <SolidDivButton>Upload Image</SolidDivButton>
              </label>
              <p className={`${style.homeTitleDestop}`}>or drop a file</p>
            </div>
            <div className={`${style.homeTitleTryContainer}`}>
              <div className={`${style.homeTitleDestop}`}>
                <p>No image?</p>
                <p>Try one of these:</p>
              </div>
              <div className={`${style.homeTitleMobile}`}>
                <p>No image? Try one of these:</p>
              </div>
              <div className={`${style.homeTitlePreselectContainer}`}>
                {preselects.map((d, i) => (
                  <Image key={`preselect-${i}`} src={`https://cdn.decormatters.com/assets/${d.thumb}`} className={`${style.homeTitlePreselectImage}`} width={50} height={50} alt="" onClick={e => handlePresetImage(d.original)} />
                ))}
              </div>
            </div>
            <p>
              By uploading an image you agree to our <Link href="/terms">Terms of Service</Link>. To learn more about how DecorMatters handles your personal data, check our <Link href="/policy">Privacy Policy</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
  // return (
  //   <Container>
  //     <h1>DecorMatters Home Design App & Decorating Game</h1>
  //     <h2>Play Design Games, Decorate Real Rooms, Find Inspiration, and Unleash Your Inner Designer.</h2>
  //   </Container>
  // )
}

export default HomeTitle
