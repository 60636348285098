import unlimitedFurniture from '../../../assets/membership/unlimited-furniture.svg'
import unlimitedMyroom from '../../../assets/membership/unlimited-myroom.svg'
import unlimitedMydecor from '../../../assets/membership/unlimited-mydecor.svg'
import animatedFilter from '../../../assets/membership/animated-filter.svg'
import mydecorOrganizer from '../../../assets/membership/mydecor-organizer.svg'
import backgroundEraser from '../../../assets/membership/background-eraser.svg'
import itemSearch from '../../../assets/membership/item-search.svg'
import accessWishlist from '../../../assets/membership/access-wishlist.svg'
import specialBadge from '../../../assets/membership/special-badge.svg'
import ar from '../../../assets/membership/ar-ruler.svg'
import model from '../../../assets/membership/3d-model.svg'
import unlimitedTemplates from '../../../assets/membership/unlimited-templates.svg'
import uneditableItems from '../../../assets/membership/uneditableItems.svg'
import designDrafts from '../../../assets/membership/designDrafts.svg'
import arView from '../../../assets/membership/arView.svg'

export const privileges = [
  {
    label: 'Unlimited Furniture',
    background: unlimitedFurniture,
    type: 'furniture'
  },
  {
    label: 'Unlimited MyRoom',
    background: unlimitedMyroom,
    type: 'room'
  },
  {
    label: 'Unlimited MyDecor',
    background: unlimitedMydecor,
    type: 'decor'
  },
  {
    label: 'Unlimited Templates',
    background: unlimitedTemplates,
    type: 'templates'
  },
  {
    label: 'Animated Filter',
    background: animatedFilter,
    type: 'filter'
  },
  // {
  //   label: 'MyDecor Organizer',
  //   background: mydecorOrganizer,
  //   type: 'organizer'
  // },
  {
    label: 'Background Eraser',
    background: backgroundEraser,
    type: 'background'
  }
  // {
  //   label: 'AR Ruler',
  //   background: ar,
  //   type: 'ar'
  // },
  // {
  //   label: '3D Model',
  //   background: model,
  //   type: '3d'
  // },
  // {
  //   label: 'Uneditable Items',
  //   background: uneditableItems,
  //   type: 'uneditable'
  // },
  // {
  //   label: 'Design Drafts',
  //   background: designDrafts,
  //   type: 'designDrafts'
  // },
  // {
  //   label: 'Item Search',
  //   background: itemSearch,
  //   type: 'search'
  // },
  // {
  //   label: 'Access Wishlist',
  //   background: accessWishlist,
  //   type: 'wishlist'
  // },
  // {
  //   label: 'AR View',
  //   background: arView,
  //   type: 'ar'
  // },
  // {
  //   label: 'Special Badge',
  //   background: specialBadge,
  //   type: 'badge'
  // }
]
